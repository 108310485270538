import {useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {clearCookie} from '../../utils/cookies';

const Logout = ({history}) => {
  useEffect(() => {
    (async () => {
      const redirect = () => {
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
        }, 300);
      };
      try {
        await Auth.signOut({global: true});
        localStorage.clear();
        clearCookie();
        redirect();
      } catch (e) {
        localStorage.clear();
        clearCookie();
        redirect();
      }
    })();
  }, [history]);

  return null;
};

export default Logout;
