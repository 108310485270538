import queryString from 'query-string';
import { hasProperty } from './hasProperty';

const goToUrl = (search = '') => {
  window.location.href = hasProperty(search, 'adm') ? (
    process.env.REACT_APP_ADMIN_URL
  ) : (
    process.env.REACT_APP_ACADEMY_URL
  ) ;
};

export const decodeUrlAndRedirect = (search, urlEncode) => {
  try {
    const urlDecode = atob(urlEncode);
    if (urlDecode.includes('@') || urlDecode.indexOf(process.env.REACT_APP_DOMAIN) === -1) {
      goToUrl(search);
    } else {
      window.location.href = urlDecode;
    }
  } catch (e) {
    goToUrl(search);
  }
};

export const redirectTo = (query = '') => {
  const search = queryString.parse(query);
  if (hasProperty(search, 'redirect') && search.redirect) {
    decodeUrlAndRedirect(search, search.redirect);
  } else if (hasProperty(search, 'signUp')) {
    decodeUrlAndRedirect(search, search.signUp);
  } else if (hasProperty(search, 'login')) {
    decodeUrlAndRedirect(search, search.login);
  } else {
    goToUrl(search);
  }
};
