import {hasProperty} from './hasProperty';

export const getNoDefineUrl = (search = '') => {
  return hasProperty(search, 'adm')
    ? btoa(process.env.REACT_APP_ADMIN_URL)
    : btoa(process.env.REACT_APP_ACADEMY_URL);
};

export const sanitizeUrl = (url) => {
  const urlString = atob(url);
  if (
    urlString.indexOf(process.env.REACT_APP_ADMIN_URL) !== -1 ||
    urlString.indexOf(process.env.REACT_APP_ADMIN_URL) !== -1
  ) {
    return url;
  }
  return getNoDefineUrl();
};

export const getRedirect = (search = '') => {
  if (hasProperty(search, 'redirect')) {
    const redirect = sanitizeUrl(search.redirect) || getNoDefineUrl(search);
    return `/login?redirect=${redirect}`;
  }
  if (hasProperty(search, 'signUp')) {
    const redirect = sanitizeUrl(search.signUp) || getNoDefineUrl(search);
    return `/cadastro?redirect=${redirect}`;
  }
  if (hasProperty(search, 'login')) {
    const redirect = sanitizeUrl(search.login) || getNoDefineUrl(search);
    return `/login?redirect=${redirect}`;
  }
  return `/login?redirect=${getNoDefineUrl(search)}`;
};
